
.page-container {
    background-color: #eee;
}

.page-container__body {
    margin: 2px;
    min-height: 95vh;
    background-color: #fff;
}

.page-container > .page-container__body {
    .body__frame {
        margin: 0px 0px 0px 2px;
    }
}

.page-container__header {
    background: #fff;
    padding: 0px 24px;
    margin: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.body__footer {
    background: #fff;
}
