#root {
    height: 100%;
    width: 100%;
}

.ant-table-tbody {
    tr:nth-child(2n) {
        background-color: #fafafa;
    }
}

// .ant-table {
//     box-shadow: 0px 0px 1px 1px #bebebe;
// }

.ant-table-thead {
    tr th {
        background-color: #f1f1f1;
    }
}

.icon--check-circle > svg {
    width: 1.25em;
    height: 1.25em;
}


blockquote {

    padding: 0.2rem 0.5rem;
    background-color: #fafafa;
    border-left: 2px solid #ddd;
}